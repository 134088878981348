:root {
  /* Controlling page colours */
  --font-main: #fbf4c6;
  --font-secondary: #a2a483;
  --background: #495340;

  /* Constant sizes */
  --gap-width: 24px;
  --max-width: 960px;
}

@font-face {
  font-family: "SelfModernRegular";
  src: url("./assets/fonts/SelfModernRegular.woff2") format("woff2"),
    url("./assets/fonts/SelfModernRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SelfModernItalic";
  src: url("./assets/fonts/SelfModernItalic.woff2") format("woff2"),
    url("./assets/fonts/SelfModernItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
